@import "../index";

.image-upload-container {
    position: relative;
  }
  
  .uploadphoto {
    display: inline-block;
    cursor: pointer;
  }
  
  .image-preview {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px; /* Adjust font size for the icon */
    padding: 2px; /* Optional: to ensure the icon fits */
  }
  .password-input-container {
    position: relative;
  }
  
  .password-input {
    padding-right: 40px; /* Add space for the icon */
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  